import React, { useState } from 'react';
import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Img,
  Heading,
  Grid,
  Flex,
  Button,
  SimpleGrid,
  Center,
  // Container,
} from '@chakra-ui/react';
import Slider from 'react-slick';
import Link from 'next/link';
import Image from 'next/image';
import { useAppSelector } from '@/store';
import 'slick-carousel/slick/slick.css';
import LoginModal from '@/components/modals/LoginModal';
// import CustomButton from '@/components/common/CustomButton';

const members = [
  {
    id: 1,
    img: '/png/new-members/Alchmey Black-Gold Logo.png',
    text: 'Alchemy Capital',
    siteLink: 'https://www.alchemycapital.com/',
  },
  {
    id: 2,
    img: '/png/new-members/Eques Capital.png',
    text: 'Eques Capital',
    siteLink: 'https://www.equescapital.in/',
  },
  {
    id: 3,
    img: '/png/new-members/ITI.png',
    text: 'ITI Group',
    siteLink: 'https://www.itiorg.com/',
  },
  {
    id: 4,
    img: '/png/new-members/Java Capital.png',
    text: 'Java Capital',
    siteLink: 'https://www.javacapital.co/',
  },
  {
    id: 5,
    img: '/png/new-members/Kochi Holdings.png',
    text: 'Kochi Holdings',
    siteLink: 'https://kochiho.in/',
  },
  {
    id: 6,
    img: '/png/new-members/Multiply Ventures.png',
    text: 'Multiply Ventures',
    siteLink: 'https://www.multiplyventures.com/web/',
  },
  {
    id: 7,
    img: '/png/new-members/Northern Arc.png',
    text: 'Northern Arc',
    siteLink: 'https://www.northernarc.com/',
  },
  {
    id: 8,
    img: '/png/new-members/Pravega.png',
    text: 'Pravega Ventures',
    siteLink: 'https://www.pravegavc.com/',
  },
  {
    id: 9,
    img: '/png/new-members/Sony Innovation Fund.png',
    text: 'Sony Innovation Fund',
    siteLink: 'https://www.sonyinnovationfund.com/',
  },
  {
    id: 10,
    img: '/png/new-members/White Oak AMC.png',
    text: 'White Oak AMC',
    siteLink: 'https://mf.whiteoakamc.com/',
  },
  {
    id: 11,
    img: '/png/new-members/AlphaMine.png',
    text: 'Alphamine IM',
    siteLink: 'https://alphamineim.com/',
  },
  {
    id: 12,
    img: '/png/new-members/Anicut.png',
    text: 'Anicut Capital',
    siteLink: 'https://www.anicutcapital.com/',
  },
  {
    id: 13,
    img: '/png/new-members/AuxanoVentures.png',
    text: 'Auxano Ventures',
    siteLink: 'https://auxano.in/',
  },
  {
    id: 14,
    img: '/png/new-members/BCapitalGroup.png',
    text: 'B Capital Group',
    siteLink: 'https://www.bcapgroup.com/ ',
  },
  {
    id: 15,
    img: '/png/new-members/BhiveFund.png',
    text: 'Bhive Fund',
    siteLink: 'https://bhivealts.ai/',
  },
  {
    id: 16,
    img: '/png/new-members/HoulianLokey.png',
    text: 'Houlian Lokey',
    siteLink: 'https://hl.com/',
  },
  {
    id: 17,
    img: '/png/new-members/PepperIndiaAdvisors.png',
    text: 'Pepper India Advisors',
    siteLink: 'https://www.pepper-advantage.com/',
  },
  {
    id: 18,
    img: '/png/new-members/PraxisGA.png',
    text: 'Praxis GA',
    siteLink: 'https://www.praxisga.com/',
  },
  {
    id: 19,
    img: '/png/new-members/RTP.png',
    text: 'RTP Global',
    siteLink: 'https://rtp.vc/',
  },
  {
    id: 20,
    img: '/png/new-members/SheCapital.png',
    text: 'She Capital',
    siteLink: 'https://www.shecapital.vc/',
  },
  {
    id: 21,
    img: '/png/new-members/Unifi.png',
    text: 'Unifi Capital',
    siteLink: 'https://www.unificap.com/',
  },
  {
    id: 22,
    img: '/png/new-members/Merak.png',
    text: 'Merak Ventures',
    siteLink: 'https://www.merakventures.com',
  },
];

const settings = {
  dots: true,
  infinite: true,
  autoplay: false,
  autoplaySpeed: 5000,
  arrows: false,
  adaptiveHeight: true,
  speed: 500,
  slidesToShow: 1,
  centerMode: false,
  className: 'center',
  slidesToScroll: 1,
  centerPadding: '60px',
  appendDots: (dots: any) => (
    <Box
      backgroundColor="transparent"
      borderRadius="10px"
      padding="10px"
      zIndex="10"
      position="relative"
      margin-top="25px"
      left={{ xs: '-37%', sm: '-41%', md: '-44%', lg: '-46%', xl: '-574px' }}
    >
      <ul style={{ margin: '0px' }}>{dots}</ul>
    </Box>
  ),
  dotsClass: 'slick-dots slick-homeTesti',
  responsive: [
    {
      breakpoint: 1224,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const MembersGrid = () => (
  <Grid
    templateColumns="repeat(4, 1fr)"
    gap="30px 16px"
    role="group"
    mr="0px"
    maxW="1208px"
  >
    {members.map((member) => (
      <Box
        _groupHover={{ cursor: 'pointer' }}
        maxH="164px"
        key={member.id}
        pb="22px"
        bgColor="white"
        border="1px solid"
        borderColor="bg.borderBg"
        cursor="pointer"
      >
        <Box
          h="100px"
          bgColor={member.id === 3 ? 'black' : 'white'}
          boxShadow="2px 4px 50px rgba(100, 100, 140, 0.13)"
        >
          <Img src={member.img} alt="company logo" />
        </Box>
        <Box w="100%" h="1px" bgColor="bg.gray" mb="19px" />
        <Flex justify="space-between" alignItems="center" pl="24px" pr="10px">
          <Heading
            variant="heroNum"
            color="text.mediumBlack"
            _groupHover={{
              color: 'primary.500',
            }}
            cursor="pointer"
          >
            {member.text}
          </Heading>
          <Box _groupHover={{ display: 'block' }} display="none">
            <Image
              src="/svg/cardExt.svg"
              alt="ext"
              width={19.85}
              height={19.85}
            />
          </Box>
        </Flex>
      </Box>
    ))}
  </Grid>
);

const MembersTabs: React.FC = () => {
  const isLoggedIn = useAppSelector((state) => state.user?.isLoggedIn);

  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Flex
        as="section"
        w="100%"
        pb="90px"
        bgColor="bg.lightWhite"
        pos="relative"
        justify="center"
        align="center"
        h="100%"
        flexDir="column"
      >
        <Box
          width="100%"
          maxW={{ xs: '90%', xl: '1240px' }}
          mx="20px"
          mt="4rem"
        >
          <Tabs variant="unstyled">
            <TabList mb="24px">
              <Tab
                _selected={{
                  color: 'text.darkHead',
                  fontWeight: '800',
                  fontSize: { xs: '22px', sm: '28px', md: '30px', lg: '42px' },
                  lineHeight: { xs: '30px', md: '40px', lg: '54.6px' },
                }}
                p="0"
                color="text.darkGray"
                fontSize={{ xs: '22px', sm: '28px', md: '30px', lg: '42px' }}
                lineHeight={{ xs: '30px', md: '40px', lg: '54.6px' }}
                fontWeight="800"
              >
                New Members
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel p="0">
                <Slider {...settings}>
                  {[1, 2, 3, 4].map((item2) => {
                    const data =
                      item2 === 1
                        ? members.slice(0, 6)
                        : item2 === 2
                        ? members.slice(6, 12)
                        : item2 === 3
                        ? members.slice(12, 18)
                        : members.slice(18);
                    return (
                      <Box minH="200px" key={item2}>
                        <SimpleGrid
                          width="100%"
                          columnGap="10px"
                          rowGap="10px"
                          columns={{ xs: 2, md: 3 }}
                          p="10px"
                        >
                          {data.map((member) => (
                            <Box
                              role="group"
                              key={member.id}
                              bgColor="white"
                              border="1px solid"
                              borderColor="bg.borderBg"
                              boxShadow=" 0px 4px 84px rgba(100, 100, 140, 0.13)"
                              cursor="pointer"
                            >
                              <Center
                                w="100%"
                                h="100px"
                                borderBottom="solid 1px"
                                borderColor="bg.borderBg"
                              >
                                <Image
                                  src={member.img}
                                  alt={member.text}
                                  width="100"
                                  height="90"
                                />
                              </Center>
                              <Link href={member.siteLink} target="_blank">
                                <Flex
                                  justify="space-between"
                                  alignItems="center"
                                  pl="24px"
                                  pr="10px"
                                  pt="18px"
                                  pb="22px"
                                >
                                  <Heading
                                    variant="heroNum"
                                    color="text.mediumBlack"
                                    _groupHover={{
                                      color: 'primary.500',
                                    }}
                                    cursor="pointer"
                                  >
                                    {member.text}
                                  </Heading>

                                  <Box
                                    _groupHover={{
                                      display: 'block',
                                    }}
                                    display="none"
                                  >
                                    <Image
                                      src="/svg/cardExt.svg"
                                      alt="external icon"
                                      width={19.85}
                                      height={19.85}
                                    />
                                  </Box>
                                </Flex>
                              </Link>
                            </Box>
                          ))}
                        </SimpleGrid>
                      </Box>
                    );
                  })}
                </Slider>
              </TabPanel>
            </TabPanels>
          </Tabs>
          <Flex
            mt="34px"
            justify={{ xs: 'center', md: 'space-between' }}
            alignItems="center"
            color="white"
            flexWrap="wrap"
            rowGap="20px"
          >
            <Flex
              rowGap="20px"
              flexWrap="wrap"
              justify={{
                xs: 'center',
                sm: 'space-around',
                md: 'space-between',
              }}
              w={{ xs: '100%', md: '480px' }}
            >
              {!isLoggedIn && (
                <Link passHref href="/membership/membership-form">
                  <Button
                    w="227.85px"
                    colorScheme="primary"
                    h="55px"
                    borderRadius="0"
                  >
                    Become A Member
                  </Button>
                </Link>
              )}

              <Link
                passHref
                href="/membership"
                aria-label="View All Members Button"
              >
                <Button
                  w="227.85px"
                  colorScheme="secondary"
                  h="55px"
                  borderRadius="0"
                >
                  View All Members
                </Button>
              </Link>
            </Flex>
            {!isLoggedIn && (
              <Button
                w="227.85px"
                colorScheme="secondary"
                h="55px"
                borderRadius="0"
                onClick={() => setIsOpen(true)}
              >
                Member Login
              </Button>
            )}
          </Flex>
        </Box>
      </Flex>
      <LoginModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default MembersTabs;
