import {
  Text,
  Img,
  Heading,
  Box,
  SimpleGrid,
  Flex,
  Button,
  Link,
} from '@chakra-ui/react';

import React from 'react';

const information = [
  {
    title: 'Private Investment Funds Webinar Series 2024',
    link: 'https://www.morganlewis.com/events/asia-private-investment-funds-webinar-series',
    cover: 'https://space.levo.so/WGLBUT82/BannerMorganLewis-OaJSLBL3g4Jw.jpg',
    // desc: `Please note that the webinar is restricted to Alternative Asset Funds only.`,
    btnText: 'Register Now',
    // code : "Partner Promo Code: SVCACONF_IVCA",
    // date : " 16 May 2024",
    desc: (
      <>
        <Text fontWeight={800} variant="featureText" textAlign="justify">
          Please note that the webinar is restricted to Alternative Asset Funds
          only.
        </Text>
        <Text variant="featureText" textAlign="justify">
          Location: Online
        </Text>
      </>
    ),
  },
  {
    title: 'The world of private capital meets in Asia',
    link: 'https://informaconnect.com/superreturnasia/',
    cover: '/jpeg/SuperReturn.jpeg',
    // desc: `Seeking balance in a turbulent world. Location: Fairmont Singapore, 80 Bras Basah Rd, Singapore 189560 Partner Promo Code: SVCACONF_IVCA`,
    btnText: 'Register Now',
    // code : "Partner Promo Code: SVCACONF_IVCA",
    // date : " 16 May 2024",
    desc: (
      <Text variant="featureText" textAlign="justify">
        <Text as="span" textAlign="left">
          Location: Marina Bay Sands Convention Centre Singapore.
        </Text>
        <br />
        Partner Promo Code: FKR3538IVCA
      </Text>
    ),
  },
  //   {
  //     title: 'Annual Capital Market Conference',
  //     link: 'https://capam.ficci.in/',
  //     cover: '/jpeg/capam.jpeg',
  //     // desc: `Seeking balance in a turbulent world. Location: Fairmont Singapore, 80 Bras Basah Rd, Singapore 189560 Partner Promo Code: SVCACONF_IVCA`,
  //     btnText: 'Register Now',
  //     // code : "Partner Promo Code: SVCACONF_IVCA",
  //     // date : " 16 May 2024",
  //     desc: (
  //       <Text variant="featureText" textAlign="justify">
  //         <Text as="span" textAlign="left">
  //           Location: Hotel Sofitel (BKC), Mumbai
  //         </Text>
  //       </Text>
  //     ),
  //   },

  //   {
  //     title: 'The 23rd HKVCA China Private Equity Summit 2024',
  //     link: 'https://cpes.hkvca.com.hk/',
  //     cover: '/png/hkvca-summit.png',
  //     btnText: 'Register Now',
  //     desc: (
  //       <Text variant="featureText" textAlign="justify">
  //         <Text as="span" textAlign="left">
  //           Location: Hong Kong Convention and Exhibition Centre
  //         </Text>
  //         <br />
  //         <Text as="span" textAlign="left">
  //           IVCA members get 20% off non-member rates with the promo code
  //         </Text>
  //         <br />
  //         Promo Code: IVCA_CPES24
  //       </Text>
  //     ),
  //   },

  // {
  //   title: 'SuperReturn Emerging Markets',
  //   link: 'https://bit.ly/42C7ca8',
  //   cover: 'https://space.levo.so/WGLBUT82/7051866539202002002.png',
  //   desc: 'Connecting growth market GPs with LPs from around the globe. 10% discount code : FKR2675IVSM',
  //   btnText: 'Book Tickets, 10% Off',
  // },
  // {
  //   title: 'SuperReturn Asia',
  //   link: 'https://bit.ly/3NdOGiu',
  //   cover: 'https://space.levo.so/WGLBUT82/7077574911420662587.png',
  //   desc: 'The best private capital networking event in Asia returns to Singapore this September! 10% discount – VIP code: FKR2536IVCA',
  //   btnText: 'Book Tickets, 10% Off',
  // },
  // {
  //   title: 'Demystifying Dematerialization of AIFs',
  //   link: 'https://forms.gle/EPdqkqESZZjGNzpY6',
  //   cover: 'https://space.levo.so/WGLBUT82/7104745679971500437.jpeg',
  //   // desc: 'The best private capital networking event in Asia returns to Singapore this September! 10% discount – VIP code: FKR2536IVCA',
  //   btnText: 'Register Now',
  // },
  // {
  //   title: 'Green Investment Forum',
  //   link: 'https://indiagreeninvestment.com/',
  //   cover: 'https://space.levo.so/WGLBUT82/7113499207844418496.jpg',
  //   desc: 'We are glad to be a part of the India Green Investment Forum, hosted by @Impact Investors Council, as the Network Patron. The forum is set to take place on the 6th & 7th of November, 2023, at The Grand, New Delhi',
  //   btnText: 'Read more',
  // },
  //   {
  //     title: 'SVCA Conference 2023',
  //     link: 'https://svca.glueup.com/event/75251/',
  //     cover: '/png/IVCA_conf_2023.png',
  //     desc: "Join SVCA on 7 June as we gather the movers and shakers in the region, from policy makers to investors, fund managers and service providers who help complete this ecosystem in Asia's most exciting market: Singapore. This is a must-attend event for senior professionals from the Venture and Private Capital sector, where we promote mindshare and discuss pertinent topics facing the industry today. IVCA Members enjoy Partner Rates using Promo Code SVCACONFERENCE-IVCA2023.",
  //     btnText: 'Learn More',
  //   },
];

const PartnerEvents: React.FC = () => (
  <Flex
    justifyContent={{ xs: 'center', lg: 'flex-start' }}
    pl={{ xs: '0%', lg: '10%', xl: '8%' }}
    bgColor="bg.lightWhite"
    py="5px"
  >
    <Box
      id="media-coverage"
      //   as="section"
      pt={{ xs: '60px' }}
      maxW="container.2xl"
      w="90%"
    >
      <Heading
        p="0"
        //   color="text.darkGray"
        fontSize={{ xs: '22px', sm: '28px', md: '30px', lg: '42px' }}
        lineHeight={{ xs: '30px', md: '40px', lg: '54.6px' }}
        fontWeight="800"
      >
        Partner Events
      </Heading>
      <SimpleGrid
        w="100%"
        mt="24px"
        gap="2rem"
        columns={{ xs: 1, md: 2, xl: 3 }}
      >
        {information.map((info) => (
          //   <a key={info.title} href={info.link} target="_blank" rel="noreferrer">
          <Box boxShadow="xl" borderRadius="xl" overflow="hidden">
            <Img
              height="250px"
              w="100%"
              //   objectFit="cover"
              src={info.cover}
              alt={info.title}
            />
            <Box p="1rem">
              <Heading as="h3" variant="featureTitle">
                {info.title}
              </Heading>
              <Box
                overflowY="scroll"
                css={{
                  '&::-webkit-scrollbar': {
                    display: 'none',
                  },
                }}
              >
                {typeof info?.desc === 'string' ? (
                  <Text variant="featureText" textAlign="justify">
                    {info?.desc}
                  </Text>
                ) : (
                  info?.desc
                )}
              </Box>
              <Link href={info.link} aria-label="learn-more" target="_blank">
                <Button
                  colorScheme="primary"
                  aria-label="learn-more"
                  w={{ xs: '175px' }}
                  h={{ xs: '30px', md: '40px' }}
                  mt="15px"
                >
                  {info.btnText}
                </Button>
              </Link>
            </Box>
          </Box>
          //   </a>
        ))}
      </SimpleGrid>
    </Box>
  </Flex>
);

export default PartnerEvents;
