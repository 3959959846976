import React from 'react';
// import { TbCalendarTime } from 'react-icons/tb';
// import { HiMapPin } from 'react-icons/hi2';
import { Box, Link, Text } from '@chakra-ui/react';
import HeroSectionSlider from '@/containers/landingPage/HeroSectionSlider';
import Calendar from './Calendar';
import FeatureInfo from './featureSection/FeatureInfo';
import FeatureMap from './featureSection/FeatureMap';
import HomeDemoTestimonial from './HomeDemoTestimonial';
import MembersTabs from './MembersTabs';
import PartnerEvents from './PartnerEvents';
// March 18-20, 2024 Bharat Mandapam, Pragati Maidan, New Delhi
const slides = [
  {
    id: 1,
    title: 'IVCA GreenReturns Summit 2024',
    desc: '2nd-3rd Dec 2024 | Taj Palace Hotel, New Delhi',
    showBtn: true,
    buttons: {
      text: 'Learn More',
      url: 'https://www.ivca.in/greenReturns-2024',
    },
    banner: {
      lg: 'https://space.levo.so/WGLBUT82/GRSWEbbanner1-FUG2XjB9LnJu-ImvcBQTJr2N2.jpg',
    },
    contactInfo: (
      <Box
        w={{ xs: '100%', md: '60%' }}
        mt={{ xs: '-20px', md: '0px' }}
        mb={{ xs: '10px', md: '20px' }}
        display={{ xs: 'none', md: 'block' }}
      >
        <Text color="white" fontSize="xl" fontWeight="bold" mb="22px">
          India&apos;s Trillion Dollar Investment Opportunity in Climate Action
        </Text>
        {/* <Text color="white" fontSize="xl" fontWeight="bold">
              For more information and partnership opportunities, write to us at
              <Link
                fontWeight={600}
                href="mailto:Aakriti@ivca.in"
                color="primary.500"
                px="6px"
              >
                Aakriti@ivca.in
              </Link>
              and
              <Box as="br" display={{ xs: 'block', md: 'none' }} />
              <Link
                fontWeight={600}
                href="mailto:Arya@ivca.in"
                color="primary.500"
                px="6px"
              >
                Arya@ivca.in
              </Link>
            </Text> */}
      </Box>
    ),
  },
  {
    id: 1,
    title: 'Welcome to IVCA',
    desc: 'Indian Venture and Alternate Capital Association (IVCA) is a not-for-profit, apex industry body promoting the alternate capital industry and fostering a vibrant investing ecosystem in India.',
    showBtn: true,
    buttons: { text: 'About Us', url: '/about-us' },
    banner: { lg: '/webp/homeSlideOne.webp' },
  },

  {
    id: 1,
    title: 'The Indian Venture IPO and Exit Strategies Forum 2024',
    desc: '14th November 2024 | Mumbai',
    showBtn: true,
    buttons: {
      text: 'Learn More',
      url: 'https://www.ivca.in/indian-venture-ipo-and-exit-strategies-forum',
    },
    banner: {
      lg: 'https://space.levo.so/WGLBUT82/IVCAIPObanner-OpenFile-tryGJ0C6PfS9.jpg',
    },
  },

  // {
  //   id: 105,
  //   // desc: `March 18-20, 2024\nBharat Mandapam, Pragati Maidan,\nNew Delhi`,
  //   showBtn: true,
  //   buttons: { text: 'Register Now',
  //   url: 'https://startupmahakumbh.org/register-now.php?utm_source=ivca&utm_medium=Banner',
  //   external: true
  // },
  //   banner: { lg: '/jpg/mahakumbh-2024.jpg' },
  // },
  // {
  //   id: 100,
  //   // title: 'IVCA Conclave',
  //   desc: '26TH & 27TH FEBRUARY 2024,         TAJ LANDS END, MUMBAI, INDIA',
  //   showBtn: true,
  //   buttons: { text: '', url: '' },
  //   banner: { lg: '/jpeg/banner.jpeg' },
  //   topComp: (
  //     <Flex
  //       flexDirection={{
  //         xs: 'column',
  //         md: 'row',
  //       }}
  //       align="center"
  //       alignSelf={{ xs: 'flex-start', md: 'flex-start' }}
  //       gap="1rem"
  //       mb="8px"
  //     >
  //       <Img
  //         src="https://space.levo.so/WGLBUT82/7142422434722293524.png"
  //         alt="ivca_logo"
  //         w={{ xs: 40, md: 56 }}
  //         pb={{ xs: 12, md: 4 }}
  //         objectFit="cover"
  //       />
  //     </Flex>
  //   ),
  // },

  {
    id: 54,
    title: 'SEBI AI Simplification',
    desc: 'SEBI AI Simplification of Requirements',
    showBtn: true,
    buttons: { text: 'Read More', url: '/sebiupdates/accredited-investor' },
    // banner: { lg: '/webp/citySkyline.webp' },
    banner: { lg: 'https://space.levo.so/WGLBUT82/7130124319659207262.png' },
  },
  // {
  //   id: 0,
  //   title: 'EY-IVCA Startup Governance Navigator Report',
  //   desc: `In association with Pratithi Investments`,
  //   showBtn: true,
  //   buttons: {
  //     text: 'View Report',
  //     url: 'https://www.ivca.in/corporate-governance',
  //     external: true,
  //   },
  //   banner: { lg: 'https://space.levo.so/WGLBUT82/7130124319659207262.png' },
  //   topComp: (
  //     <Flex
  //       flexDirection={{
  //         xs: 'column',
  //         md: 'row',
  //       }}
  //       align="center"
  //       alignSelf={{ xs: 'flex-start', md: 'flex-start' }}
  //       gap="1rem"
  //       mb="8px"
  //     >
  //       <Img
  //         src="https://space.levo.so/WGLBUT82/7130808173085061645.png"
  //         alt="ivca_logo"
  //         w={{ xs: 40, md: 56 }}
  //         pb={{ xs: 12, md: 4 }}
  //         objectFit="cover"
  //       />
  //     </Flex>
  //   ),
  // },
  {
    id: 54,
    title: 'Regulatory Reporting - SEBI',
    desc: 'Regulatory Reporting by AIFs - Circular Issued by SEBI',
    showBtn: true,
    buttons: {
      text: 'Read More',
      url: '/sebiupdates/sebi-quarterly-reporting-by-aifs',
    },
    banner: { lg: '/webp/citySkyline.webp' },
  },
  //   {
  //     id: 5,
  //     title: '',
  //     desc: '',
  //     showBtn: false,
  //     buttons: {
  //       text: 'Read More',
  //       url: '/vc-101',
  //       external: false,
  //     },
  //     banner: {
  //       xs: '/png/mobile.png',
  //       md: '/png/tablet.png',
  //       lg: '/png/desktop.png',
  //     },
  //     topComp: (
  //       <Link href="/vc-101" cursor="pointer" aria-label="btn">
  //         <Button
  //           variant="heroBtn"
  //           aria-label="btn"
  //           w={{ xs: '130px', md: '157px' }}
  //           h={{ xs: '28px', md: '50px' }}
  //           pos="absolute"
  //           bottom={{ xs: '85px', md: '80px' }}
  //           m="auto"
  //           left="50%"
  //           zIndex={15}
  //           transform="translateX(-50%)"
  //           cursor="pointer"
  //         >
  //           Learn More
  //         </Button>
  //       </Link>
  //     ),
  //   },
  //   {
  //     id: 14,
  //     title: (
  //       <Text
  //         color="white"
  //         fontSize={{ xs: '40px', sm: '40px', md: '56px', lg: '70px' }}
  //         fontWeight="900"
  //         lineHeight="1.2"
  //       >
  //         IVCA SECONDARIES <br /> CONFERENCE
  //       </Text>
  //     ),
  //     desc: (
  //       <Flex mt="20px" flexDir="column" gap="20px">
  //         <Flex
  //           color="white"
  //           fontSize="17px"
  //           fontWeight="500"
  //           gap="8px"
  //           align="center"
  //         >
  //           <TbCalendarTime size={24} color="#123141" />
  //           20th July, 2023 (Tue), 09:30 AM IST
  //         </Flex>
  //         <Flex
  //           color="white"
  //           fontSize="17px"
  //           fontWeight="500"
  //           gap="8px"
  //           align="center"
  //         >
  //           <HiMapPin size={24} color="#123141" />
  //           Trident, BKC, Mumbai
  //         </Flex>
  //       </Flex>
  //     ),
  //     showBtn: true,
  //     buttons: {
  //       text: 'Learn More',
  //       url: '/events/ivca-secondaries-conference',
  //       external: false,
  //     },
  //     banner: {
  //       lg: '/png/white.png',
  //       md: '/png/white.png',
  //       xs: '/png/white.png',
  //     },

  //     topComp: (
  //       <Flex
  //         flexDirection={{
  //           xs: 'column',
  //           md: 'row',
  //         }}
  //         w="100%"
  //         align="center"
  //         alignSelf={{ xs: 'flex-start', md: 'flex-start' }}
  //         justifyContent={{ xs: 'flex-start', md: 'space-between' }}
  //         gap={{ xs: '1rem', md: '2rem' }}
  //         mb="8px"
  //       >
  //         <Img
  //           src="/png/ivca_logo_white.png"
  //           alt="ivca_logo"
  //           objectFit="cover"
  //           maxW={{ xs: '150px', md: '200px' }}
  //           flex={1}
  //           //   width={200}
  //           //   height={22.22}
  //         />
  //         <Flex
  //           columnGap={{ md: '2rem' }}
  //           alignSelf="stretch"
  //           justify="space-around"
  //         >
  //           <Img
  //             src="/png/Khaitan.png"
  //             alt="khaitan&co"
  //             maxW={{ xs: '150px', md: '200px' }}
  //             flex={1}
  //             // w={{ xs: '30%', md: '15%' }}
  //           />
  //           <Img
  //             src="/png/goodwn.png"
  //             alt="goodwin"
  //             maxW={{ xs: '150px', md: '200px' }}
  //             flex={1}
  //             // w={{ xs: '30%', md: '15%' }}
  //           />
  //         </Flex>
  //       </Flex>
  //     ),
  //   },
  //   {
  //     id: 15,
  //     title: (
  //       <Flex flexDir="column" w={{ xs: '100%', md: '100%', lg: '100%' }}>
  //         <Text
  //           color="white"
  //           fontSize={{ xs: '40px', sm: '40px', md: '56px', lg: '70px' }}
  //           w={{ xs: '100%', md: '100%', lg: '100%' }}
  //           fontWeight="900"
  //           lineHeight="1.2"
  //         >
  //           IVCA{' '}
  //           <Text
  //             display={{ xs: 'block', md: 'inline' }}
  //             as="span"
  //             w="100%"
  //             color="#70933c"
  //           >{`'ROAD TO IPO'`}</Text>{' '}
  //         </Text>
  //         <Text
  //           color="white"
  //           mt={{ xs: '0px', md: '0px' }}
  //           fontSize={{ xs: '40px', sm: '40px', md: '56px', lg: '70px' }}
  //           fontWeight="900"
  //           lineHeight="1"
  //         >
  //           CONFERENCE
  //         </Text>
  //       </Flex>
  //     ),
  //     desc: (
  //       <Flex mt="10px" flexDir="column" gap="15px">
  //         <Flex
  //           color="white"
  //           fontSize="17px"
  //           fontWeight="500"
  //           gap="8px"
  //           align="center"
  //         >
  //           <TbCalendarTime size={24} color="#70933c" />
  //           25th July, 2023 (Tue), 11:30 onwards
  //         </Flex>
  //         <Flex
  //           color="white"
  //           fontSize="17px"
  //           fontWeight="500"
  //           gap="8px"
  //           align="center"
  //         >
  //           <HiMapPin size={24} color="#70933c" />
  //           Trident, BKC, Mumbai
  //         </Flex>
  //       </Flex>
  //     ),
  //     showBtn: true,
  //     buttons: {
  //       text: 'Learn More',
  //       url: '/events/ivca-road-to-ipo-conference',
  //       external: false,
  //     },
  //     banner: {
  //       lg: '/png/roadToIPO.png',
  //       md: '/png/roadToIPO.png',
  //       xs: '/png/roadToIPO.png',
  //     },
  //     topComp: (
  //       <Flex
  //         flexDirection={{
  //           xs: 'column',
  //           md: 'row',
  //         }}
  //         w="100%"
  //         align="center"
  //         alignSelf={{ xs: 'flex-start', md: 'flex-start' }}
  //         justifyContent={{ xs: 'flex-start', md: 'space-between' }}
  //         gap="2rem"
  //         mb="8px"
  //       >
  //         <Img
  //           src="/png/ivca_logo_white.png"
  //           alt="ivca_logo"
  //           objectFit="contain"
  //           maxW={{ xs: '130px', md: '200px' }}
  //           flex={1}
  //         />
  //         <Flex
  //           columnGap="0rem"
  //           alignSelf="stretch"
  //           justify={{ xs: 'space-around' }}
  //         >
  //           <Img
  //             src="/png/avendus.png"
  //             alt="avendus"
  //             objectFit="contain"
  //             w={{ xs: '30%', md: '24%' }}
  //             maxW={{ xs: '100px', md: '200px' }}
  //           />
  //           <Img
  //             src="/png/indus-law.png"
  //             alt="indus-law"
  //             objectFit="contain"
  //             w={{ xs: '30%', md: '24%' }}
  //             maxW={{ xs: '100px', md: '200px' }}
  //           />
  //           <Img
  //             src="/png/deloitte.png"
  //             alt="deloitter"
  //             objectFit="contain"
  //             w={{ xs: '30%', md: '24%' }}
  //             maxW={{ xs: '100px', md: '200px' }}
  //           />
  //         </Flex>
  //       </Flex>
  //     ),
  //   },
  // {
  //   id: 6,
  //   title: 'India Venture Capital Report 2023',
  //   desc: '2022 signalled a recalibration for the ecosystem after record capital influx over the last few years.',
  //   showBtn: true,
  //   buttons: {
  //     text: 'Read More',
  //     url: 'media/7041667442726785792',
  //     external: true,
  //   },
  //   banner: { lg: 'https://space.levo.so/WGLBUT82/7041695846542678994.png' },
  //   topComp: (
  //     <Flex
  //       flexDirection={{
  //         xs: 'column',
  //         md: 'row',
  //       }}
  //       align="center"
  //       alignSelf={{ xs: 'flex-start', md: 'flex-start' }}
  //       gap="1rem"
  //       mb="8px"
  //     >
  //       <Image
  //         src="/png/ivca_logo_white.png"
  //         alt="ivca_logo"
  //         width={200}
  //         height={22.22}
  //       />
  //       <Image
  //         src="/svg/logo_red_bain.svg"
  //         alt="bain&company"
  //         width={200}
  //         height={22.22}
  //       />
  //     </Flex>
  //   ),
  // },

  //   {
  //     id: 2,
  //     title: 'IVCA Conclave 2023',
  //     desc: "IVCA conclave is India's premiere private equity and venture capital event. Focused on the Private Equity and Venture Capital investments in India, this exclusive event is designed to be an ideal environment for exchange of ideas, exploring business opportunities, securing long term source of capital, assessing regulatory scenario and high-end networking",
  //     showBtn: true,
  //     buttons: {
  //       text: 'Learn More',
  //       url: 'https://ivcaconclave.com/',
  //       external: true,
  //     },
  //     banner: { lg: 'https://space.levo.so/WTO7YYQ0/6993170405305614680.jpg' },
  //   },

  // {
  //   id: 3,
  //   title:
  //     'Private credit can offer leveraging capabilities to PE, VC funds, say panellists at IVCA Conclave',
  //   desc: 'Private credit is a mainstream asset class today with a number of end users and it can provide significant leveraging capabilities to private equity and venture capital funds, panellists at the IVCA Conclave 2023 said.',
  //   showBtn: true,
  //   buttons: {
  //     text: 'Read More',
  //     url: 'https://www.businesstoday.in/personal-finance/story/private-credit-can-offer-leveraging-capabilities-to-pe-vc-funds-say-panellists-at-ivca-conclave-371098-2023-02-22',
  //     external: true,
  //   },
  //   banner: { lg: '/webp/newBanner.webp' },
  //   topComp: (
  //     <Box alignSelf={{ xs: 'flex-start', md: 'flex-start' }} mb="8px">
  //       <Image
  //         src="/png/conclave_logo_white.png"
  //         alt="bain&company"
  //         width={256}
  //         height={110}
  //       />
  //     </Box>
  //   ),
  // },
  //   {
  //     id: 4,
  //     title: 'PE/VC Awards 2023',
  //     desc: 'Congratulations to our Winners!',
  //     showBtn: true,
  //     buttons: {
  //       text: 'Read More',
  //       url: '/awards',
  //       external: false,
  //     },
  //     banner: { lg: 'https://space.levo.so/WGLBUT82/7041356861716794924.jpg' },
  //   },
  //   {
  //     id: 5,
  //     title: '',
  //     desc: '',
  //     showBtn: true,
  //     buttons: {
  //       text: 'Know More',
  //       url: 'https://www.startupindia.gov.in/nsa2022results/',
  //       external: false,
  //     },
  //     banner: { lg: 'https://space.levo.so/WGLBUT82/7117505345073441190.png' },
  //   },
  //   {
  //     id: 5,
  //     title: '',
  //     desc: '',
  //     showBtn: false,
  //     buttons: {
  //       text: 'Know More',
  //       url: 'https://www.startupindia.gov.in/nsa2022results/',
  //       external: true,
  //     },
  //     banner: {
  //       xs: 'https://space.levo.so/WGLBUT82/7117505345073441190.png',
  //       md: 'https://space.levo.so/WGLBUT82/7117505345073441190.png',
  //       lg: 'https://space.levo.so/WGLBUT82/7117505345073441190.png',
  //     },
  //     topComp: (
  //       <Link
  //         href="https://www.startupindia.gov.in/nsa2022results/"
  //         isExternal
  //         cursor="pointer"
  //         aria-label="btn"
  //       >
  //         <Button
  //           variant="heroBtn"
  //           aria-label="btn"
  //           w={{ xs: '130px', md: '157px' }}
  //           h={{ xs: '28px', md: '50px' }}
  //           pos="absolute"
  //           bottom={{ xs: '85px', md: '80px' }}
  //           m="auto"
  //           left="50%"
  //           zIndex={15}
  //           transform="translateX(-50%)"
  //           cursor="pointer"
  //         >
  //           Know More
  //         </Button>
  //       </Link>
  //     ),
  //   },
];

const HomePage: React.FunctionComponent = () => (
  <>
    <HeroSectionSlider slides={slides} />
    <FeatureInfo />
    <FeatureMap />
    {/* <SebiStatsContainer/> */}
    <Calendar />
    <PartnerEvents />
    <MembersTabs />
    <HomeDemoTestimonial />
    {/* <HomeTestimonial /> */}
  </>
);

export default HomePage;
