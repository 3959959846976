import React from 'react';
import type { NextPage } from 'next';
import Layout from '@/components/Layout';
import HomePage from '@/containers/landingPage/HomePage';

const Home: NextPage = () => (
  <Layout
    title="Home"
    description="Indian Venture and Alternate Capital Association (IVCA) is a not-for-profit, apex industry body promoting the alternate capital industry and fostering a vibrant investing ecosystem in India."
    url=""
    hideTopStrip={false}
  >
    <HomePage />
  </Layout>
);

export default Home;
